body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.9) 10%,
      rgba(0, 0, 0, 0.43)
    ),
    url("../public/images/roomBack.jpg");
}
/* Calendario */
.rbc-label.rbc-time-header-gutter {
  width: 51px;
}
.rbc-row-content {
  display: none;
}
.rbc-time-view .rbc-allday-cell {
  height: 0 !important;
}

.navbar {
  background-color: transparent;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
}
.brandCol {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0%, -50%);
}
.containerText {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: clamp(2rem, 2.8vw, 5rem);
  font-family: Raleway;
  font-weight: bold;
}
.textCol {
  width: 100% !important;
  margin: auto;
  display: block;
  text-align: center;
  color: #fff;
  font-size: calc(23px + (50 - 21) * ((100vw - 300px) / (2600 - 350)));
}
.btnGoogle {
  box-shadow: 4px 5px 16px #000000;
}
.Login {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.9) 10%,
      rgba(0, 0, 0, 0.43)
    ),
    url("../public/images/2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sesion {
  position: absolute;
  color: white;
  top: 0px;
  right: 0;
  width: 300px;
}

.imgProfile {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.modal.show{
  transition: backdrop-filter .5s ;
  backdrop-filter: blur(15px);
}
.bg-salaVip, .bg-salaEditorial, .bg-salaWar{
  background: #000428;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #004e92, #000428);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #004e92, #000428); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.bg-salaVideo{
  background: #0f0c29;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.bg-salaPresidencia{
  background: #BF953F; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C); /* Chrome 10-25, Safari 5.1-6 */
  background:linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
}

.rooms {
  margin-top: 0px;
}
.imgRooms {
  width: 100%;
}

.imgRoomsEditorial,
.imgRoomsWar {
  height: 350px !important;
  object-fit: cover;
  width: 100%;
}

.imgRoomsVip {
  height: 450px !important;
  width: 100%;
  object-fit: cover;
}

.textTitle {
  position: relative;
  font-size: calc(25px + (45 - 21) * ((100vw - 300px) / (2600 - 350)));
}

.sidebarRooms h5 {
  position: relative;
  cursor: pointer;
  transition: all 0.5s;
  font-size: calc(18px + (25 - 21) * ((100vw - 300px) / (2600 - 350)));
}
.sidebarRooms h5:hover {
  font-weight: 700;
  padding-left: 10px;
}

.sideBar{
  display: flex;
  height: 100vh;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  flex-direction: column;
  flex-wrap: wrap;
  align-content:flex-start;
  align-items:flex-start;
  padding:0 30px;
  z-index:0;
}

.containerRooms{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 50px;
  grid-gap: 5px 0;
}

.bgVip,
.bgEditorial,
.bgHall,
.bgWar,
.bgVideo,
.bgPresi {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bgVip {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.95) 5%,
      rgba(0, 0, 0, 0.3)
    ),
    /* url('../public/images/VIP_img.png'); */ url("../public/images/2.jpg");
}

.bgHall {
  background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.95) 5%,
          rgba(0, 0, 0, 0.3)
  ),
    /* url('../public/images/VIP_img.png'); */ url("../public/images/hall.jpg");
}

.bgEditorial {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.95) 5%,
      rgba(0, 0, 0, 0.3)
    ),
    /* url('../public/images/Editorial_img.png'); */
      url("../public/images/editorial.jpg");
}

.bgWar {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.95) 5%,
      rgba(0, 0, 0, 0.3)
    ),
    /* url('../public/images/war_room_img.png'); */
      url("../public/images/bgWar.jpg");
}

.bgVideo {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.95) 5%,
      rgba(0, 0, 0, 0.3)
    ),
    /* url('../public/images/war_room_img.png'); */
      url("../public/images/bgVideo.jpg");
}

.bgPresi {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.95) 5%,
      rgba(0, 0, 0, 0.3)
    ),
    /* url('../public/images/VIP_img.png'); */
      url("../public/images/presidenciaSala.jpg");
}

.rbc-btn-group {
  background-color: white;
}
.rbc-toolbar-label {
  width: 45px;
  color: #000;
}
.espacio {
  padding-left: 16% !important;
}
.rbc-month-view {
  background-color: white;
}
.rbc-time-view {
  background-color: white;
}
.rbc-agenda-view {
  background-color: white;
}
.logoInvert {
  filter: invert(1);
}

.plus {
  width: 17px;
  filter: invert(45%) sepia(84%) saturate(2790%) hue-rotate(194deg)
    brightness(101%) contrast(106%);
  margin-right: 20px;
  animation-name: rotateImg;
  animation-iteration-count: 1;
  animation-duration: 0.8s;
  animation-timing-function: ease-in;
}
.disabledAnimation {
  animation-duration: 0s !important;
  margin-right: 8px;
  vertical-align: baseline;
  width: 14px;
}
@keyframes rotateImg {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.textReservations {
  cursor: pointer;
}

.btnCloseSession{
  position: relative;
  z-index:1;
}

@media (max-width: 992px) {
  /* Login */
  .brandCol {
    margin-top: 20px;
  }
  .textCol {
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 720px) {
  .sesion {
    top: 10px;
    width: 120px;
  }
  .sesion .row .col-12.d-flex {
    position: fixed;
    bottom: 40px;
    right: 20px;
    width: auto;
  }

  .textTitle {
    top: 200px;
  }
  .sidebarRooms .text1 {
    top: 270px;
  }
  .sidebarRooms .text2 {
    top: 320px;
  }
  .sidebarRooms .text3 {
    top: 370px;
  }
}

@media (min-width: 575px) and (max-width: 720px) {
  .sesion .row .col-sm-6 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (max-width: 768px) and (orientation: landscape) {
  .Login {
    height: 200vh;
  }
  .bgVip,
  .bgEditorial,
  .bgWar,
  .bgVideo,
  .bgPresi {
    height: 200vh;
  }
}
